.btn:not(:disabled):hover {
  box-shadow: none !important;
}

.settings-desktop {
  display: flex;
  align-items: center;
}

.btn.settings-mobile {
  display: none;
  height: 35px !important;
  line-height: 35px !important;
  width: 35px !important;
  font-size: 13px !important;
}

.avatar-icon-wrapper {
  width: 45px;
}

@media (max-width: 1380px) {
  .settings-desktop {
    display: none;
  }

  .btn.settings-mobile {
    display: inline-block;
  }
}

@media (max-width: 1099.98px) {
  .app-header-menu {
    display: block;
  }
  .search-wrapper-open .app-header--pane+.app-header--pane {
    display: none;
  }
}

@media (max-width: 440px) {
  .user-box {
    margin-left: 0 !important;
  }

  .app-header--pane .d-inline-block.pr-2 {
    padding-right: .2rem !important;
  }

  .app-wrapper:not(.search-wrapper-open) .app-header .app-header--pane:first-child {
    width: 80px;
  }
}
