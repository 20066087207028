.nav-logo a i img {
  width: 30px;
  animation: none;
}

.app-sidebar--dark .sidebar-navigation ul li ul li > a {
  padding: 6px 0 6px 2.30769rem;
  height: auto;
  white-space: inherit;
}

.app-sidebar--dark .sidebar-navigation ul li ul li > a span.sidebar-item-label {
  height: auto;
}

.sidebar-icon-indicator {
  transform: rotate(0deg);
  transition: all .2s ease-in-out;
  opacity: .4;
  margin-left: auto;
  width: 18px;
  height: 18px;
}

.sidebar-icon-indicator > * {
  width: 18px !important;
}

.app-sidebar-logo--text b {
  font-family: 'CovesLight', -apple-system, sans-serif;
  letter-spacing: 2px;
  font-size: 25px;
}

.app-sidebar--header {
  height: auto !important;
  min-height: auto !important;
  padding: 20px !important;
}

.app-sidebar--header .app-sidebar-logo {
  width: 100%;
}

.app-sidebar--header img {
  width: 100%;
  max-width: 120px;
  margin: 0 auto;
}

.app-sidebar--header .collapse-sidebar-btn {
  margin-left: 10px;
}

@media (min-width: 850px) {
  .show-btn {
    display: none !important;
  }
}

@media (max-width: 530px) {
  .app-footer .row {
    display: flex;
    flex-direction: column;
  }

  .powered-by {
    width: 100%;
    margin-bottom: 5px;
  }

  .networks-footer {
    margin-bottom: 10px;
  }

  .app-footer .col:first-child {
    justify-content: center;
    margin: 10px 0;
  }

  .app-footer .col:last-child {
    flex-direction: column;
  }
}
