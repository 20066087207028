@media only screen and (min-width: 1100px) {
  .has-side-dock  {
    margin-right: 60px !important;
  }
}

.rbc-current, .rbc-now {
  color: #ffffff;
}

.rbc-event {
  padding: 0;
  background-color: unset;
  position: relative;
  height: 31px;
}

.rbc-today {
  background-color: #4191ff;
}

.rbc-custom-event {
  border: none;
  border-radius: .29rem;
  box-shadow: none;
  cursor: pointer;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 5px 8px;
  position: absolute;
  text-align: left;
  text-overflow: ellipsis;
  top: 0;
  white-space: nowrap;
  width: 100%;
}

.rbc-month-row:hover>div>.rbc-today {
  background-color: #4191ff;
}

.rbc-month-row:hover>div>.rbc-today:hover {
  background: #50a0ff;
}

.modal-full {
  width: 80%;
  height: 90%;
  max-width: unset;
  max-height: unset;
}

.modal-content-full {
  height: inherit;
}

.react-bs-table-no-data {
  text-align: center;
}

.fav-button, .rating-button {
  display: none !important;
}

@media only screen and (max-width: 576px) {
  .modal-full {
    width: 100%;
  }
  .modal-dialog {
    padding: .5rem;
    margin: 0;
  }
}

@media (max-width: 1200px) {
  .app-sidebar {
    position: fixed !important;
    left: -320px !important;
  }
  .app-content {
    padding-left: 0 !important;
  }
  .app-header {
    left: 0 !important;
    flex-shrink: 0;
    padding: 0 1rem;
  }
  .app-header .toggle-mobile-sidebar-btn {
    display: flex;
  }
  .header-search-wrapper {
    border-left: 1px solid rgba(7,9,25,.1);
    border-right: 1px solid transparent;
    padding: .28571rem .5rem;
    margin: 0 1rem;
  }
}

@media screen and (max-width: 50em) {
  .flex-responsive {
    display: inline-block !important;
    margin-bottom: 15px;
  }
  .flex-responsive > div {
    width: 100% !important;
    display: inline-block;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.app-page-title {
  margin: 0 12px 0.5rem;
  padding: 1rem;
  justify-content: center;
}

.app-page-title--heading h1 {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
}

.rbc-toolbar {
  padding: 0 1rem;
}

@media (min-width: 1366px) {
  .rbc-toolbar {
    flex-direction: row;
  }
}

@media (min-width: 1100px) {
  .app-header-fixed .app-content {
    padding-top: 68px;
  }
}

.app-content--inner {
  padding: 0 5px;
}

input.form-control, .form-group .input-group-prepend {
  height: calc(1.5em + .5rem + 2px);
  font-size: .875rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.card-header {
  padding: 0.25rem 1.25rem;
}

table, .table {
  font-size: 12px !important;
}

@media (min-width: 1100px) {
  .app-sidebar-collapsed .app-sidebar:not(:hover) .app-sidebar--content .sidebar-navigation .sidebar-header i {
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
}

.modal-xxl {
  max-width: 95%;
}

.grid-pagination-desktop .num-registros {
  position: absolute;
  left: 1rem;
}

.grid-pagination-desktop {
  margin-top: .25rem;
}

@media (min-width: 992px) {
  .calendario-saindo-tela .rdtPicker {
    right: 0;
  }
}

.tooltip {
  pointer-events: none !important;
}

.sidebar-navigation > ul > li {
  padding: 0 16px !important;
}

.sidebar-navigation > ul > li > a {
  padding: 0 !important;
}

.sidebar-navigation ul li ul li {
  padding: 1px 0 1px 16px !important;
  list-style-type: none;
}

.sidebar-navigation ul li ul li a {
  padding-left: 0 !important;
}

.sidebar-navigation ul li ul li a::after {
  display: none;
}

.grid-pagination-desktop .rc-pagination {
  width: 100%;
}

